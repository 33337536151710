import React from 'react'
import SEO from '../components/seo'
import METADATA from '../metadata/metadata'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import './about.scss'

const AboutPage = ({ data: { alPicture, chrisPicture } }) => {
  return (
    <>
      <SEO
        title={METADATA.about.title}
        description={METADATA.about.description}
      />
      <div className="About_containerWrap">
        <h1 className="About_heading">About us</h1>
        <section className="About">
          <div className="About_text">
            <p>
              For over 35 years, we’ve been providing friendly and reliable
              building services in and around the Northamptonshire area. We aim
              to complete every project, big or small, to an incredibly high
              standard that you can be proud of for years to come.
            </p>
            <p>
              We offer a full range of domestic and commercial building
              services, including but not limited to:
            </p>{' '}
            <ul className="About_list">
              <div>
                <li className="About_listItem">Home extensions</li>
                <li className="About_listItem">
                  Kitchen remodeling and refitting
                </li>
                <li className="About_listItem">Renovations</li>
                <li className="About_listItem">Conversatories</li>
                <li className="About_listItem">Bathroom installations</li>
                <li className="About_listItem">Plastering</li>
                <li className="About_listItem">Building and bricklaying</li>
                <li className="About_listItem">Window and door refits</li>
                <li className="About_listItem">Tiling</li>
              </div>
              <div>
                <li className="About_listItem">Fencing</li>
                <li className="About_listItem">
                  Driveway paving and remodeling
                </li>
                <li className="About_listItem">Fascias</li>
                <li className="About_listItem">Garage and carport building</li>
                <li className="About_listItem">Carpentry</li>
                <li className="About_listItem">Patio installations</li>
                <li className="About_listItem">Guttering</li>
                <li className="About_listItem">Stonework</li>
              </div>
            </ul>
            <p>
              We mainly cover areas within a 20 mile radius of Northampton,
              including the below towns and villages. But if you’re further away
              and would like to find out if we could help, please don’t hesitate
              to get in touch.
            </p>
            <ul className="About_list">
              <div>
                <li className="About_listItem">Northampton</li>
                <li className="About_listItem">Kettering</li>
                <li className="About_listItem">Corby</li>
                <li className="About_listItem">Wellingborough</li>
                <li className="About_listItem">Olney</li>
                <li className="About_listItem">Irchester</li>
              </div>
              <div>
                <li className="About_listItem">Finedon</li>
                <li className="About_listItem">Irthlingborough</li>
                <li className="About_listItem">Sywell</li>
                <li className="About_listItem">Thrapston</li>
                <li className="About_listItem">Yardley Hastings</li>
              </div>
            </ul>
            <p>
              We’re happy to discuss jobs of all shapes and sizes, so please do
              get in touch for a free no obligation quote today.
            </p>
            <span className="About_signOff_name">Al</span>
            <span className="About_signOff_title">
              Owner, AGHicks Building Services
            </span>
          </div>
          <div className="About_pics">
            <div className="About_picWrap">
              <Img fluid={alPicture.childImageSharp.fluid} />
              <span>Alan</span>
            </div>
            <div className="About_picWrap">
              <Img fluid={chrisPicture.childImageSharp.fluid} />
              <span>Chris</span>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    alPicture: file(relativePath: { eq: "al.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chrisPicture: file(relativePath: { eq: "chris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPage
